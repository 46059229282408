<template>
  <!-- content -->
  <div class="blog-detail-wrapper">
    <b-row>
      <!-- blogs -->
      <b-col cols="12">
        <b-card
          :img-src="blogDetail.blog.img"
          img-top
          img-alt="Blog Detail Pic"
          :title="showData.activity_name"
        >
          <!-- eslint-disable vue/no-v-html -->
          <div
            class="blog-content"
            v-html="showData.activity_description"
          />
          <hr>
          <div>
            <small>
              <feather-icon
                icon="HeartIcon"
                size="20"
              /> {{ showData.likes.length }} likes
            </small>
          </div>
        </b-card>
      </b-col>
      <!--/ blogs -->

      <!-- blog comment -->
      <b-col
        id="blogComment"
        cols="12"
        class="mt-2"
      >
        <h6 class="section-label">
          Comment
        </h6>
        <b-card
          v-for="(comment,index) in blogDetail.comments"
          :key="index"
        >
          <b-media no-body>
            <b-media-aside class="mr-75">
              <b-avatar
                :src="comment.avatar"
                size="38"
              />
            </b-media-aside>
            <b-media-body>
              <h6 class="font-weight-bolder mb-25">
                {{ comment.userFullName }}
              </h6>
              <b-card-text>{{ comment.commentedAt }}</b-card-text>
              <b-card-text>
                {{ comment.commentText }}
              </b-card-text>
              <b-link>
                <div class="d-inline-flex align-items-center">
                  <feather-icon
                    icon="CornerUpLeftIcon"
                    size="18"
                    class="mr-50"
                  />
                  <span>Reply</span>
                </div>
              </b-link>
            </b-media-body>
          </b-media>
        </b-card>
      </b-col>
      <!--/ blog comment -->

      <!-- Leave a Blog Comment -->
      <b-col
        cols="12"
        class="mt-2"
      >
        <h6 class="section-label">
          Leave a Comment
        </h6>
        <b-card>
          <b-form>
            <b-row>
              <b-col sm="6">
                <b-form-group class="mb-2">
                  <b-form-input
                    name="name"
                    placeholder="Name"
                  />
                </b-form-group>
              </b-col>
              <b-col sm="6">
                <b-form-group class="mb-2">
                  <b-form-input
                    name="email"
                    type="email"
                    placeholder="Email"
                  />
                </b-form-group>
              </b-col>
              <b-col sm="6">
                <b-form-group class="mb-2">
                  <b-form-input
                    name="website"
                    placeholder="Website"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group class="mb-2">
                  <b-form-textarea
                    name="textarea"
                    rows="4"
                    placeholder="Website"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-checkbox
                  id="checkbox-1"
                  v-model="commentCheckmark"
                  name="checkbox-1"
                  class="mb-2"
                >
                  Save my name, email, and website in this browser for the next time I comment.
                </b-form-checkbox>
              </b-col>
              <b-col cols="12">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                >
                  Post Comment
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
      </b-col>
      <!--/ Leave a Blog Comment -->
    </b-row>
    <!--/ blogs -->
  </div>
  <!--/ content -->

</template>

<script>
import {
  BFormInput, BMedia, BAvatar, BMediaAside, BMediaBody, BLink, BFormGroup,
  BCard, BRow, BCol, BBadge, BCardText, BForm, BFormTextarea, BFormCheckbox, BButton,
} from 'bootstrap-vue'
import axios from 'axios'
import Ripple from 'vue-ripple-directive'
import { kFormatter } from '@core/utils/filter'
import store from '@/store'
import {
  // eslint-disable-next-line no-unused-vars
  ref, watch, onUnmounted, reactive, computed, watchEffect,
} from '@vue/composition-api'
import annpuncementsDataModule from '../announementsModule'
import announcement from '../announcements'

export default {
  components: {
    BFormInput,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BLink,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    // eslint-disable-next-line vue/no-unused-components
    BBadge,
    BCardText,
    BForm,
    BFormTextarea,
    BFormCheckbox,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      search_query: '',
      commentCheckmark: '',
      // detail
      blogDetail: {
        blog: {
          // eslint-disable-next-line global-require
          img: require('@/assets/images/banner/banner-12.jpg'),
          title: 'The Best Features Coming to iOS and Web design',
          // eslint-disable-next-line global-require
          avatar: require('@/assets/images/portrait/small/avatar-s-7.jpg'),
          userFullName: 'Ghani Pradita',
          createdTime: 'Jan 10, 2020',
          tags: ['First', 'Seconde'],
          content:
        '<p>Before you get into the nitty-gritty of coming up with a perfect title, start with a rough draft: your working title. What is that, exactly? A lot of people confuse working titles with topics. Let\'s clear that Topics are very general and could yield several different blog posts. Think "raising healthy kids," or "kitchen storage." A writer might look at either of those topics and choose to take them in very, very different directions.A working title, on the other hand, is very specific and guides the creation of a single blog post. For example, from the topic "raising healthy kids," you could derive the following working title See how different and specific each of those is? That\'s what makes them working titles, instead of overarching topics.</p><h4>Unprecedented Challenge</h4><ul class="mb-2"><li>Preliminary thinking systems</li><li>Bandwidth efficient</li><li>Green space</li><li>Social impact</li><li>Thought partnership</li><li>Fully ethical life</li></ul>  ',
          comments: 19100,
          bookmarked: 139,
          UserComment: [
            {
              // eslint-disable-next-line global-require
              avatar: require('@/assets/images/portrait/small/avatar-s-6.jpg'),
              fullName: 'Willie Clark',
              comment:
            'Based in London, Uncode is a blog by Willie Clark. His posts explore modern design trends through photos and quotes by influential creatives and web designer around the world.',
            },
          ],
        },
        comments: [
          {
            // eslint-disable-next-line global-require
            avatar: require('@/assets/images/portrait/small/avatar-s-9.jpg'),
            userFullName: 'Chad Alexander',
            commentedAt: 'May 24, 2020',
            commentText:
          'A variation on the question technique above, the multiple-choice question great way to engage your reader.',
          },
        ],
      },
      blogSidebar: {},
      socialShareIcons: [
        'GithubIcon',
        'GitlabIcon',
        'FacebookIcon',
        'TwitterIcon',
        'LinkedinIcon',
      ],
    }
  },
  setup() {
    const ANNOUNCE_MODULE = 'announceData'
    // Register module
    if (!store.hasModule(ANNOUNCE_MODULE)) store.registerModule(ANNOUNCE_MODULE, annpuncementsDataModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ANNOUNCE_MODULE)) store.unregisterModule(ANNOUNCE_MODULE)
    })

    const { fetD, showData } = announcement()

    return {
      fetD,
      showData,
    }
  },
  created() {
    axios.get('https://activities.muslimdo.com/api/internalops/activities/4/announcement')
      .then(res => {
        console.log(res.data.data.activity)
      })
  },
  methods: {
    kFormatter,
    tagsColor(tag) {
      if (tag === 'Quote') return 'light-info'
      if (tag === 'Gaming') return 'light-danger'
      if (tag === 'Fashion') return 'light-primary'
      if (tag === 'Video') return 'light-warning'
      if (tag === 'Food') return 'light-success'
      return 'light-primary'
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-blog.scss';
</style>
